import Vue from "vue";
import Vuex from "vuex";

import { auth } from "@/store/auth.module";
import { users } from "@/store/users.module";
import { versions } from "@/store/versions.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    users,
    versions,
  },
});
