import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { LOGOUT } from "@/store/auth.module";
import { store } from "@/store";
import JwtService from "@/common/services/jwt.service";

/**
 * Check if access token has expired before request and refresh it
 */
const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Reject promise if usual error
      if (
        error.response.status !== 401 ||
        error.response.config.url === "oauth/token"
      ) {
        return Promise.reject(error);
      }

      axios.interceptors.response.eject(interceptor);

      store.dispatch(LOGOUT);

      return Promise.reject(error);
    }
  );
};

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
  axios.interceptors.request.use((config) => {
    let token = JwtService.getToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API;
    setHeader();
    createAxiosResponseInterceptor();
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param config
   * @returns {*}
   */
  get(resource, slug = "", config = {}) {
    if (slug.length == 0) {
      return Vue.axios.get(`${resource}`, config);
    } else {
      return Vue.axios.get(`${resource}/${slug}`, config);
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {*}
   */
  post(resource, params, config = {}) {
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param config
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, config = {}) {
    return Vue.axios.put(`${resource}/${slug}`, params, config);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param header
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, header = "") {
    return Vue.axios.put(`${resource}`, params, header);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((error) => {
      //
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  deleteUrlWithSlug(resource, slug) {
    return Vue.axios.delete(`${resource}/${slug}`).catch((error) => {
      //
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
