import ApiService from "@/common/services/api.service";

// action types
export const GET_USERS = "getUsers";
export const CREATE_USER = "createUser";
export const UPDATE_USER = "updateUser";
export const DELETE_USER = "deleteUser";
export const GET_ROLES = "getRoles";

// getters types
export const GET_ERRORS = "getErrors";

// mutation types
export const SET_USERS = "setUsers";
export const SET_USER = "setUser";
export const UNSET_USER = "unsetUser";
export const SET_ERRORS = "setErrors";
export const SET_ROLES = "setRoles";

const state = {
  errors: [],
  users: [],
  roles: [],
};

const actions = {
  [GET_USERS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("users", payload)
        .then((response) => {
          const users = response.data.data;
          context.commit(SET_USERS, users);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERRORS, response.data.errors);
          }

          reject(response);
        });
    });
  },
  [CREATE_USER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", payload)
        .then((response) => {
          const user = response.data.data;
          context.commit(SET_USER, user);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update("users", payload.id, payload)
        .then((response) => {
          const user = response.data.data;
          context.commit(SET_USER, user);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [DELETE_USER](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.deleteUrlWithSlug("users", slug)
        .then((response) => {
          context.commit(UNSET_USER, slug);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_ROLES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("users/role", payload)
        .then((response) => {
          let rolesWithNames = response.data.data;
          const roles = rolesWithNames.map(function (value) {
            return value["name"];
          });
          context.commit(SET_ROLES, roles);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_USERS](state, users) {
    state.users = users;
  },
  [SET_USER](state, user) {
    let foundIndex = state.users.findIndex((x) => x.id === user.id);
    if (foundIndex >= 0) {
      state.users.splice(foundIndex, 1, user);
    } else {
      state.users.push(user);
    }
  },
  [UNSET_USER](state, index) {
    let foundIndex = state.users.findIndex((x) => x.id === index);
    state.users.splice(foundIndex, 1);
  },
  [SET_ERRORS](state, errors) {
    state.errors = typeof errors !== "undefined" ? errors : [];
  },
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
};

export const users = {
  state,
  actions,
  mutations,
};
