import ApiService from "@/common/services/api.service";

// action types
export const GET_VERSIONS = "getVersions";
export const CREATE_VERSION = "createVersion";
export const UPDATE_VERSION = "updateVersion";
export const DELETE_VERSION = "deleteVersion";

// getters types
export const GET_ERRORS = "getErrors";

// mutation types
export const SET_VERSIONS = "setVersions";
export const SET_VERSION = "setVersion";
export const UNSET_VERSION = "unsetVersion";
export const SET_ERRORS = "setErrors";

const state = {
  errors: [],
  versions: [],
};

const actions = {
  [GET_VERSIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("versions", payload)
        .then((response) => {
          const versions = response.data.data;
          context.commit(SET_VERSIONS, versions);
        })
        .catch(({ response }) => {
          if (response.data.errors) {
            context.commit(SET_ERRORS, response.data.errors);
          }

          reject(response);
        });
    });
  },
  [CREATE_VERSION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("versions", payload)
        .then((response) => {
          const version = response.data.data;
          context.commit(SET_VERSION, version);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [UPDATE_VERSION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update("versions", payload.id, payload)
        .then((response) => {
          const version = response.data.data;
          context.commit(SET_VERSION, version);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [DELETE_VERSION](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.deleteUrlWithSlug("versions", slug)
        .then((response) => {
          context.commit(UNSET_VERSION, slug);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
};

const mutations = {
  [SET_VERSIONS](state, versions) {
    state.versions = versions;
  },
  [SET_VERSION](state, version) {
    let foundIndex = state.versions.findIndex((x) => x.id === version.id);
    if (foundIndex >= 0) {
      state.versions.splice(foundIndex, 1, version);
    } else {
      state.versions.push(version);
    }
  },
  [UNSET_VERSION](state, index) {
    let foundIndex = state.versions.findIndex((x) => x.id === index);
    state.versions.splice(foundIndex, 1);
  },
  [SET_ERRORS](state, errors) {
    state.errors = typeof errors !== "undefined" ? errors : [];
  },
};

export const versions = {
  state,
  actions,
  mutations,
};
