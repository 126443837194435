const ACCESS_TOKEN_KEY = "access_token";
const USER = "user";

export const getToken = () => {
  return window.localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(USER));
};

export const saveTokens = (data) => {
  return new Promise((resolve) => {
    window.localStorage.setItem(ACCESS_TOKEN_KEY, data.token);

    window.localStorage.setItem(USER, JSON.stringify(data.user));

    resolve();
  });
};

export const destroyTokens = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  window.localStorage.removeItem(USER);
};

export default {
  getToken,
  saveTokens,
  destroyTokens,
  getUser,
};
